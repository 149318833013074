.appearActiveOpacity,
.enterActiveOpacity,
.exitActiveOpacity {
  transition: opacity 250ms;
}

.appearOpacity,
.enterOpacity {
  opacity: 0;
}

.appearActiveOpacity,
.appearDoneOpacity,
.enterActiveOpacity,
.enterDoneOpacity,
.exitOpacity {
  opacity: 1;
}

.exitActiveOpacity,
.exitDoneOpacity {
  opacity: 0;
}

.appearActiveTranslate,
.enterActiveTranslate,
.exitActiveTranslate {
  transition: transform 250ms;
}

.appearTranslate,
.enterTranslate {
  transform: translateY(30px);
}

.appearActiveTranslate,
.appearDoneTranslate,
.enterActiveTranslate,
.enterDoneTranslate,
.exitTranslate {
  transform: translateX(0);
}

.exitActiveTranslate,
.exitDoneTranslate {
  transform: translateY(-30px);
}

.appearActiveOpacityTranslate,
.enterActiveOpacityTranslate,
.exitActiveOpacityTranslate {
  transition: opacity 250ms, transform 250ms;
}

.appearOpacityTranslate,
.enterOpacityTranslate {
  opacity: 0;
  transform: translateY(30px);
}

.appearActiveOpacityTranslate,
.appearDoneOpacityTranslate,
.enterActiveOpacityTranslate,
.enterDoneOpacityTranslate,
.exitOpacityTranslate {
  opacity: 1;
  transform: translateX(0);
}

.exitActiveOpacityTranslate,
.exitDoneOpacityTranslate {
  opacity: 0;
  transform: translateY(-30px);
}
