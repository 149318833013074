.actionButton {
  padding: 10px;
  color: white;
  box-sizing: border-box;
  background: #048213;
  display: grid;
  place-items: center;
  font-size: 20px;
  border-radius: 5px;
  font-weight: bold;
}

.actionButton:active {
  background: #00630c;
}
