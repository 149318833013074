.row {
  display: flex;
  flex-direction: row;
  position: relative;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
