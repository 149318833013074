.tileOuter {
  border: 1px solid #ffffff;
  position: relative;
  overflow: hidden;
}

.tileInner {
  --animate-duration: 80ms;
  display: grid;
  place-items: center;
  position: absolute;
  color: #ffffff;
  font-weight: bold;
  user-select: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  border: solid #ba7702;
  display: inline-block;
  transform-origin: center center;
  z-index: 0;
}

.rightArrow {
  transform: translate(-50%,-50%) rotate(-45deg);
}

.leftArrow {
  transform: translate(-50%,-50%) rotate(135deg);
}

.default.tileOuter {
  background: #000000;
}

.correct.tileOuter {
  background: #027d00;
}

.incorrect.tileOuter {
  background: #222222;
}

.left.tileOuter {
  background: #b89600;
}

.right.tileOuter {
  background: #b89600;
}

.invalid.tileOuter {
  background: #7a0000;
}

.letter {
  z-index: 1;
}

.asterisk {
  position: absolute;
  top: 0;
  right: 0;
  color: yellow;
  text-align: center;
  z-index: 2;
}