.instructionsOuter {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.instructionsInner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #333;
  overflow: auto;
}

.content {
  width: 100%;
  position: relative;
  color: #fff;
  padding: 15px;
  box-sizing: border-box;
}

.content h2 {
  margin-top: 0;
  font-size: 2rem;
}

.content h3 {
  font-size: 1.2rem;
}

.content p {
  font-size: 1rem;
  line-height: 1.3rem;
}

.content p, h3 {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.content hr {
  border-color: #bbb;
}

@media screen and (min-width: 500px) {
  .content {
    width: 500px;
    margin: auto;
  }
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
}

.closeActionButton {
  margin: 0 auto;
}
