.keyboard {
  display: grid;
  grid-template-areas:
    "q q w w e e r r t t y y u u i i o o p p"
    ". a a s s d d f f g g h h j j k k l l ."
    "E E E z z x x c c v v b b n n m m B B B";
  grid-template-rows: 60px 60px 60px;
  grid-gap: 5px;
  width: 100%;
}

.key {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background: #aaa;
  font-weight: bold;
  font-size: 13px;
  overflow: hidden;
  position: relative;
}

.key:active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.3;
}

.key, .key:active::before {
  border-radius: 5px;
}

.key.correctMore:after, .key.shiftMore:after, .key.correctShiftMore:after {
  content: '*';
  position: absolute;
  top: 5px;
  right: 2px;
  color: yellow;
  font-size: 30px;
  line-height: 20px;
}

.key.correctShift, .key.correctShiftMore {
  background: linear-gradient(90deg, rgba(0,168,45,1) 35%, rgba(212,173,0,1) 65%);
}

.key.shift, .key.shiftMore  {
  background: #d4ad00;
}

.key.correct, .key.correctMore {
  background: #00a82d;
}

.key.incorrect {
  background: #444;
}

.backspaceIcon {
  width: 25px;
  height: 25px;
}

.checkIcon {
  width: 22px;
  height: 22px;
}
