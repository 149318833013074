.button {
  user-select: none;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  background-image :none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
