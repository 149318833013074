.app {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.headerContainer {
  min-height: 65px;
  flex: 0;
  z-index: 1; /* bottom border on top of gameboard */
}

.gameBoardsContainer {
  flex: 1;
  width: 200%;
  transform: translate3d(-50%, 0, 0);
  transition: transform ease-in-out 200ms;
  overflow: hidden;
}

.gameBoardContainer {
  display: flex;
  float: left;
  justify-content: center;
  overflow-y: auto;
  height: 100%;
  width: 50%;
}

.loaderContainer {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keyboardContainer {
  flex: 0;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (min-width: 500px) {
  .keyboardContainer {
    width: 500px;
    margin: auto;
  }
}
