.splashScreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.splashScreenContent {
  position: relative;
  top: -45px;
}

.welcome {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 17px;
}

.logo {
  margin-top: 20px;
}

.invisible {
  visibility: hidden;
}

.displayNone {
  display: none;
}
