.outer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.inner {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.toast {
  background: #333;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
}
