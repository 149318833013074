.headerOuter {
  border-bottom: 1px solid #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerInner {
  display: flex;
  width: 100%;
}

@media screen and (min-width: 500px) {
  .headerInner {
    width: 500px;
  }
}

.logo {
  flex: 0;
  color: white;
  font-weight: bold;
  font-size: 25px;
  display: grid;
  place-items: center;
  margin: 0;
  padding: 0 15px;
}

.dailyButton, .unlimitedButton {
  margin: 10px;
  height: 40px;
  display: grid;
  place-items: center;
  color: #ddd;
  background: #333;
  user-select: none;
  border: none;
  font-size: 15px;
  outline: none;
  overflow: hidden;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
}

.dailyButton:active,
.unlimitedButton:active,
.dailyButton.selected,
.unlimitedButton.selected {
  color: #222;
  background: #eee;
}

.dailyButton {
  flex: 1;
}

.unlimitedButton {
  flex: 1;
}

.helpButton {
  flex: 0 1 25px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.helpButtonInner {
  border: 2px solid #fff;
  font-size: 20px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightSpacer {
  flex: 0 1 25px;
  margin-right: 10px;
}
