.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.modalOuter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: calc(100% - 20px);
}

.modalInner {
  background:#333;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  cursor: default;
}

@media screen and (min-width: 500px) {
  .modalOuter {
    width: 500px;
  }
}

.header {
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.results {
  color: white;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
}

.timer {
  color: white;
  font-size: 22px;
  margin-bottom: 15px;
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}
