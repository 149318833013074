.closeButton {
  padding: 20px;
  color: white;
  box-sizing: border-box;
  display: grid;
  place-items: center;
  background: transparent;
  font-size: 15px;
  overflow: hidden;
}

.closeIcon {
  width: 25px;
  height: 25px;
}
